import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import cyan from '@material-ui/core/colors/cyan';
import { Seperator } from 'components';
import { FacebookButton } from 'components/psg';
import { Form, TextField, createValidator, mustBeEmail, notEmpty } from 'components/form';
import { Credential, selectAuth } from 'app';
import { signin, signinFacebook } from 'app/authSlice';
import { AuthPage } from './AuthPage';
import mobile_login from './mobile_login.png';
import { WaitButton } from '../WaitButton';

const useStyles = makeStyles((theme) =>
  createStyles({
    field: {
      margin: theme.spacing(1, 0),
    },
    heading: {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
  }),
);

const initial: Credential = {
  email: '',
  password: '',
  rememberMe: true,
};

const validate = createValidator<Credential>({
  email: [notEmpty('Email can not be empty'), mustBeEmail('Invalid email address')],
  password: notEmpty('Password can not be empty!'),
});

export function Signin() {
  const dispatch = useDispatch();
  const { status } = useSelector(selectAuth);
  const classes = useStyles();
  const location = useLocation();

  React.useEffect(() => {
    if (location.search) {
      const slug = getSlug(location.search);
      if (slug)
        setTimeout(async () => {
          dispatch(signin({ email: process.env.COACH_EMAIL!, password: process.env.COACH_PASSWORD!, slug }));
        }, 1000);
    }
  }, []);

  const handleSumbit = (data: Credential) => {
    dispatch(signin(data));
  };

  return (
    <AuthPage img={<img src={mobile_login} />} headercolor={cyan[300]} bgcolor={cyan[600]}>
      <Box p={4} display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight={380}>
        <Typography variant="h6" className={classes.heading}>
          Sign in
        </Typography>

        <Box mt={2} width="100%" display="flex" flexDirection="column">
          <Typography color="textSecondary" variant="body2" align="center" paragraph>
            If you initially registered with Facebook, please click "Sign in with Facebook"
          </Typography>
          <FacebookButton onClick={() => dispatch(signinFacebook())} disabled={status?.type === 'request'} fullWidth>
            Sign in with Facebook
          </FacebookButton>
          <Seperator>OR</Seperator>
        </Box>

        <Form<Credential> initial={initial} onSubmit={handleSumbit} validate={validate}>
          <Typography color="textSecondary" variant="body2" align="center" paragraph>
            If you registered with an email and password, enter your details here
          </Typography>

          <TextField
            name="email"
            className={classes.field}
            variant="outlined"
            label="Email address"
            fullWidth
            required
          />
          <TextField
            name="password"
            type="password"
            className={classes.field}
            variant="outlined"
            label="Password"
            fullWidth
            required
          />
          <Box mt={2}>
            <WaitButton wait={status?.type === 'request'} variant="contained" color="primary" type="submit" fullWidth>
              Sign in
            </WaitButton>
          </Box>

          <Box mt={2}>
            <Typography color="textSecondary" variant="body2" align="center">
              Don't have an account? Please <Link to="/auth/signup">Sign up</Link>.
            </Typography>
            <Typography color="textSecondary" variant="body2" align="center">
              <Link to="/auth/resetPassword">Reset your password</Link>, if you fogot.
            </Typography>
          </Box>
        </Form>
      </Box>
    </AuthPage>
  );
}

const getSlug = (searchStr: string) => {
  const searchArr = searchStr.split('&');
  if (searchArr.length === 2) {
    const tokenArr = searchArr[0].split('=');
    if (tokenArr.length === 2 && tokenArr[0] === '?token') {
      const slugArr = searchArr[1].split('=');
      if (slugArr.length === 2 && slugArr[0] === 'slug' && tokenArr[1] === process.env.COACH_TOKEN) return slugArr[1];
    }
  }
};
